<template>
  <v-footer min-height="50" app inset class="font-wight-medium footer">
    <v-row justify="center" align="center">

      <span>2.1.0 &copy; {{ new Date().getFullYear() }} <!--Di Support PL Sp. z o.o.--></span>
    </v-row>
     <!-- <v-btn class="onLogoutClick"  depressed text> -->
      <!-- <v-icon class="aaa">mdi-plus-circle-outline</v-icon> -->
      <!-- <v-icon class="aaa">mdi-account-plus-outline</v-icon> -->
      <!-- <v-icon class="aaa">mdi-account-multiple-outline</v-icon> -->
      <!-- <v-icon class="aaa">mdi-lock-reset</v-icon> -->
      <!-- Logout -->
    <!-- </v-btn> -->
  </v-footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.aaa {
  color: rgb(102, 102, 102);
}
  .v-footer {
    /* background-color: rgb(37, 56, 88) !important; */
    /* color: #DEE4E8 !important; */
  }
</style>