<template>
  <ValidationProvider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
    <v-textarea v-if="typeField === 'textarea'"
      :id="id"
      v-model="innerValue"
      :error-messages="$t(errors[0])"
      v-bind="$attrs"
      v-on="$listeners"
      rows="9"
    />
    <v-text-field v-else
      v-model="innerValue"
      :error-messages="$t(errors[0])"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>

import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    typeField: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    id: {
      type: String
    },
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    innerValue (newVal) {
      this.$emit("input", newVal);
    },
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style>

</style>