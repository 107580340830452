<template>
  <v-navigation-drawer v-model="$store.state.drawer" app clipped>
    <BaseExpandableList :items="mainPage"/>
    <v-divider/>
    <BaseExpandableList :items="tasks"/>
    <v-divider/>
    <BaseExpandableList :items="shops"/>
    <v-divider/>
    <BaseExpandableList :items="statistics"/>
    <v-divider/>
    <BaseExpandableList v-if="role === 'admin'" :items="users"/>
    <v-divider/>
  </v-navigation-drawer>
</template>

<script>
  import LocalStorageService from "@/services/LocalStorageService";
  import BaseExpandableList from "@/components/utils/BaseExpandableList";
  export default {
    components: {
      BaseExpandableList
    },
    data () {
      return {
        userItems: {},
        mainPage: [
          {
            text: "navVertical.mainPage",
            href: "/homePage",
            icon: "mdi-apps"
          }
        ],
        tasks: [
          {
            text: "navVertical.tasks",
            href: "/tasks",
            icon: "mdi-calendar-check"
          }
        ],
        shops: [
          {
            text: "navVertical.shops.header",
            icon: "mdi-cart",
            items: [
              {
                text: "navVertical.shops.manage",
                href: "/shops-manage",
                icon: "mdi-archive"
              },
              {
                text: "navVertical.shops.new",
                href: "/shops-new",
                icon: "mdi-plus-circle-outline"
              }
            ]
          }
        ],
        statistics: [
          {
            text: "navVertical.statistics",
            href: "/statistics",
            icon: "mdi-chart-bar"
          }
        ],
        users: [
          {
            text: "navVertical.users",
            href: "/users",
            icon: "mdi-account-multiple-outline"
          }
        ],
        role: ""
      }
    },
    mounted () {
      this.role = LocalStorageService.getRoleInfo();
    }
  }

</script>

<style lang="scss">

  .v-navigation-drawer {
    top: 64px !important;
  }

  .v-list-group__items > div > .v-list-item .v-list-item__title {
      padding-left: 10px !important;
  }

  .v-navigation-drawer__content .v-icon {
    margin-right: 10px !important;
    color: rgb(151, 151, 151);
  }

  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list--dense .v-list-item .v-list-item__subtitle,
  .v-application .body-2 {
    font-size: 1rem !important;
  }


</style>