import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import LocalStorageService from '@/services/LocalStorageService';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: LocalStorageService.getThemeApp() || false
  }
});
