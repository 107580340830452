<template>
  <v-container>

    <v-card :loading="loading">
      <v-toolbar style="margin-top: 7px;">
        <v-toolbar-title>
          <h1>
            <v-icon class="iconHeader">mdi-calendar-check</v-icon>
            {{ $t("views.tasks.header") }}
          </h1>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn depressed text @click="onAddTaskButtonClick()">
          <v-icon size="125%" class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t("views.tasks.btnAddTask") }}
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="tasks"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
        <template v-slot:header.title="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:header.schedule="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:header.type="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:header.settings="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:header.actions="{ header }">
          {{ $t(header.text) }}
        </template>

         <template v-slot:item="props">
            <tr :id="props.item._id">
              <td class="text-left">
                <span>{{props.item.title}}</span>
              </td>
              <td class="text-center">
                <span>{{props.item.schedule}}</span>
              </td>
              <td class="text-center">
                <span>{{$t(props.item.type)}}</span>
              </td>
              <td class="text-center">
                <span v-if="props.item.type === 'email' || props.item.type === 'reporter'">
                  <v-chip
                    class="ma-2"
                    color="default"
                    outlined
                    v-for="(item, i) in props.item.settings['emails']" :key="i"
                  >
                    {{ props.item.settings['emails'][i] }}
                  </v-chip>
                </span>
                <span v-else-if="props.item.type === 'link'">
                  <v-chip
                    class="ma-2"
                    color="default"
                    outlined
                    v-for="(item, i) in props.item.settings['url']" :key="i"
                  >
                    {{ props.item.settings['url'][i] }}
                  </v-chip>
                </span>
              </td>

              <td class="text-center">
                <v-btn v-if="props.item.settings.source.length > 0" icon color="green" :href="props.item.settings.source+ '&type=html'" target="_blank">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn @click="onEditTaskDialogForm(props.item)" icon color="blue">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="onDeleteClick(props.item._id)" icon color="red">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ConfirmationDialog ref="confirm" />
    <AddOrEditTaskDialogForm
      :taskAction="taskAction"
      :idTaskToEdit="idTaskToEdit"
      :task="taskData"
      @eventTaskSaved="refreshDataTable()"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import ConfirmationDialog from "@/components/utils/ConfirmationDialog";
import AddOrEditTaskDialogForm from "@/components/utils/AddOrEditTaskDialogForm";

export default {
  components: {
    ConfirmationDialog,
    AddOrEditTaskDialogForm
  },
  data: () => ({
    tasks: [],
    loading: false,
    sortBy: "title",
    sortDesc: false,
    taskAction: "",
    taskData: {},
    defaultJson: {
      "title": "",
      "schedule": "* * * * *",
      "type": "email",
      "settings":
          {
              "emails": [],
              "source": ""
          }
    },
    idTaskToEdit: ""
  }),
  computed: {
    headers () {
      return [
        {
          text: "views.tasks.headersTable.title",
          align: "center",
          value: "title",
          width: "90px"
        },
        {
          text: "views.tasks.headersTable.schedule",
          align: "center",
          value: "schedule",
          sortable: false
        },
        {
          text: "views.tasks.headersTable.type",
          align: "center",
          value: "type",
          width: "90px"
        },
        {
          text: "views.tasks.headersTable.settings",
          align: "center",
          value: "settings",
          sortable: false
        },
        {
          text: "views.tasks.headersTable.actions",
          align: "center",
          value: "actions",
          sortable: false,
          width: "140px"
        }
      ];
    }
  },
  async mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      try {
        const response = await axios.get(`${axios.defaults.baseURL}/taskmanager/tasks/user/${this.$store.state.loggedUserId}?limit=10000`);
        //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
        //const response = await axios.get("http://localhost:1337/tasks");
        //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
        this.tasks = response.data.result;
      } catch {
        this.$store.dispatch("setSnackbar", {
          type: "error",
          message: "errors.serverError"
        });
      } finally {
        this.loading = false;
      }
    },
    async onDeleteClick (taskId) {
      try {
        const conformation = await this.$refs.confirm.open(
          "views.tasks.confirmationDailog.deleteTitle",
          "views.tasks.confirmationDailog.deleteMessage"
        );

        if (conformation) {
          this.loading  = true;
          const response = await axios.delete(
            `${axios.defaults.baseURL}/taskmanager/task/${taskId}`
          );
          //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
          // const response = await axios.delete(
          //   `http://localhost:1337/task/${taskId}`
          // );
          //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
          const deleteTask = this.tasks.filter(
            task => task._id !== taskId
          );
          this.tasks = deleteTask;

          if (response.status === 200) {
            this.$store.dispatch("setSnackbar", {
              type: "success",
              message: "information.deletedTaskSuccessfull"
            });
            this.getDataFromApi();
          }
        }
      } catch {
        this.$store.dispatch("setSnackbar", {
          type: "error",
          message: "errors.serverError"
        });
      } finally {
        this.loading = false;
      }
    },
    refreshDataTable () {
      this.taskData = null;
      this.getDataFromApi();
    },
    onAddTaskButtonClick () {
      this.taskAction = "add";
      this.idTaskToEdit = "";
      this.taskData = this.defaultJson; //JSON.stringify(this.defaultJson, null, 4);
      this.$store.commit("SHOW_ADD_OR_EDIT_TASK_DIALOG_FORM");
    },
    onEditTaskDialogForm (item) {
      this.taskAction = "edit";
      this.idTaskToEdit = item._id;
      let jsonTaskData = {
        "title": item.title,
        "schedule": item.schedule,
        "type": item.type,
        "settings": item.settings
      }
      this.taskData = jsonTaskData; //JSON.stringify(jsonTaskData, null, 4);
      this.task = jsonTaskData
      this.$store.commit("SHOW_ADD_OR_EDIT_TASK_DIALOG_FORM");
    }
  },
  created(){
    this.$store.dispatch("getUsersAsync")
  }
}
</script>

<style lang="scss">

  th {
    font-size: 16px !important;
  }

  .theme--dark > .v-chip__content {
    color: #fff !important;
  }

  .theme--light > .v-chip__content {
    color: rgba(0, 0, 0, 0.87) !important;
  }

</style>