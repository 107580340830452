var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{staticStyle:{"margin-top":"7px"}},[_c('v-toolbar-title',[_c('h1',[_c('v-icon',{staticClass:"iconHeader"},[_vm._v("mdi-calendar-check")]),_vm._v(" "+_vm._s(_vm.$t("views.tasks.header"))+" ")],1)]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","text":""},on:{"click":function($event){return _vm.onAddTaskButtonClick()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"125%"}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("views.tasks.btnAddTask"))+" ")],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tasks,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.title",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.schedule",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.settings",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item",fn:function(props){return [_c('tr',{attrs:{"id":props.item._id}},[_c('td',{staticClass:"text-left"},[_c('span',[_vm._v(_vm._s(props.item.title))])]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(props.item.schedule))])]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t(props.item.type)))])]),_c('td',{staticClass:"text-center"},[(props.item.type === 'email' || props.item.type === 'reporter')?_c('span',_vm._l((props.item.settings['emails']),function(item,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"color":"default","outlined":""}},[_vm._v(" "+_vm._s(props.item.settings['emails'][i])+" ")])}),1):(props.item.type === 'link')?_c('span',_vm._l((props.item.settings['url']),function(item,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"color":"default","outlined":""}},[_vm._v(" "+_vm._s(props.item.settings['url'][i])+" ")])}),1):_vm._e()]),_c('td',{staticClass:"text-center"},[(props.item.settings.source.length > 0)?_c('v-btn',{attrs:{"icon":"","color":"green","href":props.item.settings.source+ '&type=html',"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.onEditTaskDialogForm(props.item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.onDeleteClick(props.item._id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)])]}}])})],1)],1),_c('ConfirmationDialog',{ref:"confirm"}),_c('AddOrEditTaskDialogForm',{attrs:{"taskAction":_vm.taskAction,"idTaskToEdit":_vm.idTaskToEdit,"task":_vm.taskData},on:{"eventTaskSaved":function($event){return _vm.refreshDataTable()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }