import axios from "axios";
import store from "@/store";
import router from "@/router";
import LocalStorageService from "@/services/LocalStorageService";

if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
  axios.defaults.baseURL = "https://noid.printcube.app";
} else {
  axios.defaults.baseURL = "https://production.printcube.app";
}

axios.defaults.headers = {
  "Content-Type": "application/json"
}

axios.defaults.timeout = 10000;

axios.interceptors.request.use(
  config => {
    const accessToken = LocalStorageService.getToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);