<template>
  <div>
    <v-btn depressed text @click="changeLanguage()" class="changeLang">
      <v-icon small class="mr-2">mdi-translate</v-icon>
      <span v-if="this.$i18n.locale === 'en'">PL</span>
      <span v-else>EN</span>
    </v-btn>
  </div>
</template>

<script>
import LocalStorageService from "@/services/LocalStorageService";

export default {
  methods: {
    changeLanguage() {
      if (LocalStorageService.getStoreSelectedLanguage() === "pl") {
        LocalStorageService.setStoreSelectedLanguage("en");
      } else {
        LocalStorageService.setStoreSelectedLanguage("pl");
      }
      this.$i18n.locale = LocalStorageService.getStoreSelectedLanguage();
    }
  }
}
</script>

<style lang="scss" scoped>

  .changeLang {
    color: rgb(193, 200, 211) !important;
  }

</style>