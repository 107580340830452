<template>
  <v-app-bar app clipped-left>
    <v-app-bar-nav-icon
      v-if="$route.name !== 'login'"
      @click="toggle"
    >
    </v-app-bar-nav-icon>
    <v-toolbar-title class="headline hidden-sm-and-down">
      <img class="mr-4" height="46" :src="require('@/assets/disupport_logo.png')" />
    </v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <span class="subheading headline">CloudPrintHub</span>
    <v-spacer></v-spacer>
    <LoadingPage />
    <LanguageSwitch />
    <v-btn icon class="settings" @click="onSettingsClick()">
      <v-icon>mdi-cog-outline</v-icon>
    </v-btn>
    <v-btn class="onLogoutClick" v-if="$store.state.isLogin" depressed text @click="onLogoutClick()">
      <v-icon>mdi-logout</v-icon>
      {{ $t("header.btn-logout") }}
    </v-btn>
  </v-app-bar>
</template>

<script>
  import axios from "axios";
  import LocalStorageService from "@/services/LocalStorageService";
  import LanguageSwitch from "@/components/utils/LanguageSwitch";
  import LoadingPage from "@/components/utils/LoadingPage";

  export default {
    components: {
      LanguageSwitch,
      LoadingPage
    },
    methods: {
      onSettingsClick() {
        this.$emit('eventShowSettings');
        // this.$store.commit("SHOW_SETTINGS_DIALOG_FORM");
      },
      toggle () {
        this.$store.commit("TOOGLE_NAV_VERTICAL");
      },
      async onLogoutClick () {
        this.$store.commit("SHOW_LOADING_PAGE");
        LocalStorageService.removeAuthInfo();
        this.$router.replace({ name: "login" });
        this.$store.commit("UNSET_LOGGED_USER");
        this.$store.dispatch("setSnackbar", {
          type: "success",
          message: "information.logout"
        });
        this.$store.commit("HIDE_LOADING_PAGE");
      }

    }
  }
</script>

<style scoped>
  .headline.hidden-sm-and-down img,
  .headline.hidden-sm-and-down span {
    vertical-align: middle;
  }
  header {
    background-color: rgb(37, 56, 88) !important;
    color:rgb(193, 200, 211) !important;
  }
  .theme--light.v-divider {
    border-color: rgb(193, 200, 211) !important;
    margin-left: 0px !important;
    width: 1px;
  }
  .theme--dark.v-divider {
    margin-left: 0px !important;
  }
  .settings,
  .onLogoutClick {
    color: rgb(193, 200, 211) !important;
    margin-left: 3px
  }
  .v-app-bar__nav-icon {
    color: rgb(193, 200, 211) !important;
  }


</style>