const TOKEN = 'token';
const SELECTED_LANGUAGE = 'en';
const DARK_THEME_APP = false;
const ROLE_INFO = 'user';

export default {
  getToken () {
    return localStorage.getItem(TOKEN);
  },
  storeAuthInfo (token) {
    localStorage.setItem(TOKEN, token);
  },
  removeAuthInfo () {
    localStorage.removeItem(TOKEN);
  },
  isLoggedIn () {
    return !!this.getToken();
  },
  setStoreSelectedLanguage (lang) {
    localStorage.setItem(SELECTED_LANGUAGE, lang);
  },
  getStoreSelectedLanguage () {
    return localStorage.getItem(SELECTED_LANGUAGE);
  },
  setThemeApp (theme) {
    localStorage.setItem(DARK_THEME_APP, theme);
  },
  getThemeApp () {
    return localStorage.getItem(DARK_THEME_APP);
  },
  setRoleInfo (roleInfo) {
    localStorage.setItem(ROLE_INFO, roleInfo);
  },
  getRoleInfo () {
    return localStorage.getItem(ROLE_INFO);
  }
};