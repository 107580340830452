<template>
  <v-list dense>
    <div v-for="item in items" :key="item.text">
      <v-list-group v-if="item.items && item.items.length > 0" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="body-2">
              <v-icon>{{ item.icon }}</v-icon>
              {{ $t(item.text) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <div v-for="subItem in item.items" :key="subItem.text">
          <v-list-item :to="subItem.href">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>{{ subItem.icon }}</v-icon>
                {{ $t(subItem.text) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-group>
      <v-list-item v-else :to="item.href ? item.href : null" exact>
        <v-list-item-title class="body-2">
          <v-icon>{{ item.icon }}</v-icon>
          {{ $t(item.text) }}
        </v-list-item-title>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>

  import LocalStorageService from "@/services/LocalStorageService"

  export default {
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      role: ""
    }),
    mounted () {
      this.role = LocalStorageService.getRoleInfo();
    }
  }

</script>

