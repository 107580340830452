<template>
  <v-main class="login">
	  <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col col="12" sm="8" md="4">
          <v-card :loading="loading" class="elevation-12">
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t("loginForm.header") }}
              </v-toolbar-title>
            </v-toolbar>
            <v-divider class="titleLine"></v-divider>
            <ValidationObserver ref="validationObserver">
              <v-form @submit.prevent="onLoginClicked()">
                <v-card-text class="pb-0">
                  <VTextFieldWidthValidation
                    v-model="email"
                    rules="required|validEmail"
                    :label="$t('loginForm.labelEmail')"
                    filled
                    prepend-icon="mdi-account"
                    autocomplete="off"
                  />
                  <VTextFieldWidthValidation
                    v-model="password"
                    rules="required"
                    :label="$t('loginForm.labelPass')"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                    filled
                    prepend-icon="mdi-lock"
                    @click:append="show = !show"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    depressed
                    :disabled="loading"
                    large
                    type="submit"
                    class="pr-10 pl-10"
                  >
                    {{ $t("loginForm.btnSignIn") }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
	  </v-container>
  </v-main>
</template>

<script>

import { ValidationObserver } from "vee-validate";
import axios from "axios";

import VTextFieldWidthValidation from "@/components/inputs/VTextFieldWidthValidation";
import LocalStorageService from "@/services/LocalStorageService";

export default {
  components: {
    ValidationObserver,
    VTextFieldWidthValidation
  },
  data: () => ({
    checkbox1: true,
    email: "",
    password: "",
    loading: false,
    show: false,
    baseURL: ""
  }),
  methods: {
    async onLoginClicked () {
      const isValid = await this.$refs.validationObserver.validate();

      if (!isValid) {
        return;
      }

      this.loading = true;

      const requestBody = {
        email: this.email,
        password: this.password
      };

      try {
        const response = await axios.post(
          `${axios.defaults.baseURL}/auth/login`,
          requestBody
        );
        LocalStorageService.storeAuthInfo(response.data.token);
        LocalStorageService.setRoleInfo(response.data.role.name);

        if(response.data.role.name == "admin"){
          this.$store.commit("IS_ADMIN", true);
        }
        this.$router.replace({ name: "homePage" });
        this.$store.commit("SET_LOGGED_USER");
        this.$store.commit("SET_LOGGED_USER_ID", response.data.id);
        this.$store.dispatch("setSnackbar", {
          type: "success",
          message: "information.loginSuccessfull"
        });
      } catch {
        this.$store.dispatch("setSnackbar", {
          type: "error",
          message: "validation.incorrectCredentials"
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  button {
    margin: 0px 10px 10px 10px;
  }

  .div {
    // background-color: red;
    width: 250px;
    margin: 0 auto 20px auto;
  }

  .checkbox1, .checkbox2 {
    display: inline-block;
  }

  .checkbox1 {
    // background-color: blue;
    width: 125px;
  }

  .checkbox2 {
    // background-color: green;
    width:125px;
  }

</style>