<template>
  <v-container>
    <h1>
      <v-icon class="iconHeader">mdi-apps</v-icon>
      {{ $t("views.homePage.header") }}
    </h1>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>