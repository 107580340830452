<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.addOrEditTaskDialogForm" persistent :scrollable="true">
      <v-overlay :opacity="0.9" :dark="$vuetify.theme.dark">
        <ValidationObserver ref="validationObserver" v-slot="{ invalid }">
          <v-card class="vCardAddTaskDialogForm" :loading="loading">
            <v-toolbar flat>
              <v-toolbar-title v-if="taskAction === 'add'">
                {{ $t("addOrEditTaskDialogForm.addTaskHeader") }}
              </v-toolbar-title>
              <v-toolbar-title v-else-if="taskAction === 'edit'">
                {{ $t("addOrEditTaskDialogForm.editTaskHeader") }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider class="titleLine"></v-divider>
            <v-form @submit.prevent="onSaveButtonClick()">
              <v-card-text>
                <div>
                    <v-text-field
                      label="Title"
                      v-model="task.title"
                    />

                    <v-select
                        :items="typeSelect"
                        label="Type"
                        v-model="task.type"
                        required
                    ></v-select>

                    <label>
                        Schedule
                    </label>
                    <VueCronEditorBuefy v-model="task.schedule" locale="en" :visibleTabs="['advanced']" :preserveStateOnSwitchToAdvanced="true" cronSyntax="basic" />

                    <v-text-field
                      label="Name"
                      v-model="task.settings.name"
                    />

                    <v-text-field
                      label="Source"
                      v-model="task.settings.source"
                      v-if="$store.isAdmin"
                    />

                    <v-select
                        :items="userList"
                        label="User"
                        v-model="user"
                        required
                    ></v-select>

                    <v-radio-group v-model="periodReport" v-if="task.type == 'reporter'" :column="true" label="Period" :row="false">
                        <v-radio
                            label="DAY"
                            value="DAY"
                        ></v-radio>
                        <v-radio
                            label="WEEK"
                            value="WEEK"
                        ></v-radio>
                        <v-radio
                            label="MONTH"
                            value="MONTH"
                        ></v-radio>
                    </v-radio-group>

                    <v-textarea
                        label="Mails"
                        v-model="mails"
                        hint="Input emails like: testemail@gmail.com,testemail2@gmail.com"
                        ></v-textarea>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  style="margin: 10px; padding: 10px 15px;"
                  color="darken-1"
                  @click="onCancelButtonClick()"
                >
                  {{ $t("addOrEditTaskDialogForm.btnCancel") }}
                </v-btn>
                <v-btn
                  depressed
                  type="submit"
                  style="margin: 10px; padding: 10px 15px;"
                  color="darken-1"
                  :disabled="invalid"
                >
                  {{ $t("addOrEditTaskDialogForm.btnSave") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </ValidationObserver>
      </v-overlay>
    </v-dialog>

  </v-row>
</template>


<script>

import { ValidationObserver } from "vee-validate";
import axios from "axios";
import VTextFieldWidthValidation from "@/components/inputs/VTextFieldWidthValidation";
import VueCronEditorBuefy from 'vue-cron-editor-buefy';

export default {
components: {
    ValidationObserver,
    VTextFieldWidthValidation,
    VueCronEditorBuefy,
  },
  props: ['taskAction','idTaskToEdit', 'task'],
  data: () => ({
      loading: false,
      jsonEditor: true,
      typeSelect: [
          {
              "value": "reporter",
              "text": "Statystyki",
          },
          {
              "value": "email",
              "text": "Monitoring",
          },
          // {
          //     "value": "link",
          //     "text": "Link",
          // }
      ],
      periodReport: null,
      user: null,
      mails: null,
  }),
  computed: {
      userList: function (){
          return this.$store.state.users.map(u => {
              return {
                  text: this.createUserLabel(u),
                  value: u.id
              }
          })
      }
  },
  watch: {
        task: function(newVal = {}, oldVal) {
            const { settings = {} } = newVal
            const { source = "", emails = [] } = settings;

            try {
                const urlSearchParams = new URLSearchParams(source.split("?")[1]);
                const params = Object.fromEntries(urlSearchParams.entries());
                this.user = params.user;
                this.periodReport = params.period;
                this.mails = emails.join(",")
            } catch (error) {
                console.log(error);
            }
        }
    },
  methods: {
    resetForm () {
      this.jsonEditor = true;
      this.$refs.validationObserver.reset();
      this.$store.commit("HIDE_ADD_OR_EDIT_TASK_DIALOG_FORM");
    },
    onCancelButtonClick () {
      this.resetForm();
    },
    async onSaveButtonClick () {
      const isValid = await this.$refs.validationObserver.validate();

      if (!isValid) {
        return;
      }

      this.loading = true;

      let url = ""
      if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
          url = "https://noid.printcube.app";
      } else {
          url = "https://production.printcube.app";
      }


      switch (this.task.type) {
          case "reporter": {
              this.task.settings.source = `${url}/statistics2/getmail?user=${this.user}&period=${this.periodReport}`;
              break;
          }
          case "email": {
                this.task.settings.source = `${url}/monitoring/getemail/${this.user}`;
              break;
          }
          case "link": {
                this.task.settings.source = `${url}/monitoring/getemail/${this.user}`;
              break;
          }
      }

      this.task.settings.emails = this.mails.split(",");
      this.task.user = this.$store.loggedUserId;

      const requestBody = this.task;  //JSON.parse(document.querySelector("#textareaTaskDialogForm").value);

      if (this.taskAction === 'add') {
        try {
          const response = await axios.post(
            `${axios.defaults.baseURL}/taskmanager/task`,
            requestBody
          );
          //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
          // const response = await axios.post(
          //   `http://localhost:1337/task`,
          //   requestBody
          // );
          //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
          this.resetForm();
          this.$store.dispatch("setSnackbar", {
            type: "success",
            message: "information.addedTaskSuccessfull"
          });
          this.$emit('eventTaskSaved');
        } catch (error) {
          if (
            error.response.data.errmsg != undefined &&
            error.response.data.errmsg.indexOf("E11000") >= 0
          ) {
            this.$store.dispatch("setSnackbar", {
              type: "error",
              message: "errors.E11000"
            });
          } else {
            this.$store.dispatch("setSnackbar", {
              type: "error",
              message: "errors.serverError"
            });
          }
        } finally {
          this.loading = false;
        }
      } else if (this.taskAction === 'edit') {
        try {
          const response = await axios.put(
            `${axios.defaults.baseURL}/taskmanager/task/${this.idTaskToEdit}`,
            requestBody
          );
          //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
          // const response = await axios.put(
          //   `http://localhost:1337/task/${this.idTaskToEdit}`,
          //   requestBody
          // );
          //**** !!!!!!! TODO TYLKO LOKALNIE !!!!!!!
          this.resetForm();
          this.$store.dispatch("setSnackbar", {
            type: "success",
            message: "information.updatedTaskSuccessfull"
          });
          this.$emit('eventTaskSaved');
        } catch {
          this.$store.dispatch("setSnackbar", {
            type: "error",
            message: "errors.serverError"
          });
        } finally {
          this.loading = false;
        }
      }
    },
    createUserLabel(user){
        const { contact, address } = user;
        const { email } = contact;
        const { companyName } = address;

        return `${email} - ${companyName}`;
    }
  },
}
</script>

<style lang="scss">

  .contentAddOrEditTaskDialogForm {
    padding-top: 24px !important;
  }

  .vCardAddTaskDialogForm {
    width: 850px;
  }

  .v-overlay__content {
    max-height: 80%;
    overflow: auto;
  }

  .v-input--radio-group__input {
    margin: 0;
    // width: 200px !important;

    // .v-radio:nth-child(1) {
    //   margin-right: 40px !important;
    // }

    // .v-radio:nth-child(2) {
    //   margin-right: 0px !important;
    // }

    // div.v-input--selection-controls__input {
    //   margin-right: 10px !important;
    // }
  }

  nav.tabs{
      display:none !important;
  }

</style>
