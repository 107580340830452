<template>
  <v-btn @click="onSnackbarClick()">
    <v-snackbar
      top
      right
      multi-line
      :timeout="timeForMessages()"
      :color="type"
      v-model="isVisible"
    >
      <v-layout justify-start align-center format-text style="display: inline !important;">
        <v-icon large>{{ iconType }}</v-icon>
        {{ $t(message) }}
      </v-layout>
    </v-snackbar>
  </v-btn>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    iconType () {
      if (this.type === "success") {
        return "mdi-check-circle";
      } else if (this.type === "info") {
        return "mdi-information";
      } else if (this.type === "warning") {
        return "mdi-alert-rhombus";
      } else if (this.type === "error") {
        return "mdi-alert";
      }
      return null;
    },
    isVisible: {
      get () {
        return this.show;
      },
      set (newValue) {
        if (newValue === false) {
          this.$store.commit("UNSET_SNACKBAR");
        }
      }
    }
  },
  methods: {
    onSnackbarClick () {
      this.$store.commit("UNSET_SNACKBAR");
    },
    timeForMessages () {
      let timeout;
      if (this.type === "error") {
        timeout = 10000;
      } else {
        timeout = 5000;
      }
      return timeout;
    }
  }
}
</script>

<style lang="scss">

  .v-snack__wrapper {

    min-width: 100px !important;


    .v-icon {
      margin-right: 7px;
    }

    .format-text {
      text-transform: none !important;
      font-size: 16px !important;
    }

  }

</style>