import Vue from 'vue'
import VueRouter from 'vue-router'
import LocaleStorageService from '@/services/LocalStorageService';
import store from '@/store';

import MainPage from '@/views/MainPage';
import HomePage from '@/views/HomePage';
// import Test from '@/views/Test';
import Login from '@/views/Authenticate/Login';
import Tasks from '@/views/Tasks';
import ShopsManage from '@/views/Shops/Manage';
import ShopsNew from '@/views/Shops/New';
import Statistics from '@/views/Statistics';
import Users from '@/views/Users';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'homePage'
  },
  {
    path: '/home',
    name: 'home',
    component: MainPage,
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        component: HomePage,
      },
      {
        path: "/tasks",
        name: "tasks",
        component: Tasks,
      },
      {
        path: "/shops-manage",
        name: "shopsManage",
        component: ShopsManage,
      },
      {
        path: "/shops-new",
        name: "shopsNew",
        component: ShopsNew,
      },
      {
        path: "/statistics",
        name: "statistics",
        component: Statistics,
      },
      {
        path: "/users",
        name: "users",
        component: Users
      }
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  routes
});

const publicPages = ["login"];

router.beforeEach((to,from,next) => {
  const navigationToPublicPage = publicPages.includes(to.name);
  const loggedIn = LocaleStorageService.isLoggedIn();

  if (!loggedIn && !navigationToPublicPage) {
    LocaleStorageService.removeAuthInfo();
    store.dispatch("setSnackbar", {
      type: "info",
      message: "information.sessionExpired"
    });
    return next ({
      name: "login",
      query: {  }
    });
  }

  next();

});

export default router;