<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.settingsDialogForm" persistent>
      <v-overlay :opacity="0.9" :dark="$vuetify.theme.dark">
        <v-card class="vCardSettingsDialogForm">
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("settingsDialogForm.header") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-divider class="titleLine"></v-divider>
          <v-card-text class="contentSettingsDialogForm">
            <h3>{{ $t("settingsDialogForm.theme.header") }}</h3>
            <v-radio-group row v-model="active" style="display:flex">
              <v-radio
                name="active"
                :label="$t('settingsDialogForm.theme.light')"
                value="light"
                @click="changeTheme('light')"
              ></v-radio>
              <v-radio
                name="active"
                :label="$t('settingsDialogForm.theme.dark')"
                value="dark"
                @click="changeTheme('dark')"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="margin: 10px; padding: 10px 15px;"
              color="darken-1"
              @click="onCancelButtonClick()"
            >
              {{ $t("settingsDialogForm.btnCancel") }}
            </v-btn>
            <v-btn
              depressed
              style="margin: 10px; padding: 10px 15px;"
              color="darken-1"
              @click="onSaveButtonClick()"
            >
              {{ $t("settingsDialogForm.btnSave") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-dialog>

  </v-row>
</template>

<script>

import LocalStorageService from "@/services/LocalStorageService";

export default {
  data: () => ({
    active: null,
    themeDark: null
  }),
  mounted () {
    this.darkThemeBool();
    if (this.themeDark) {
      this.active = "dark";
    } else {
      this.active = "light";
    }
    LocalStorageService.setThemeApp(this.themeDark);
    this.$vuetify.theme.dark = this.themeDark;
  },
  methods: {
    darkThemeBool () {
      this.themeDark = LocalStorageService.getThemeApp() == 'true' ? true : false;
    },
    onCancelButtonClick () {
      this.checkThemeOnCancel();
      this.$store.commit("HIDE_SETTINGS_DIALOG_FORM");
    },
    checkThemeOnCancel () {
      this.darkThemeBool();
      if (!LocalStorageService.getThemeApp()) {
        LocalStorageService.setThemeApp(false);
        this.$vuetify.theme.dark = false;
      } else if (this.themeDark !== this.$vuetify.theme.dark) {
        this.$vuetify.theme.dark = this.themeDark;
      }
      if (this.themeDark) {
        this.active = "dark";
      } else {
        this.active = "light";
      }
    },
    onSaveButtonClick () {
      this.checkThemeOnSave();
      this.$store.commit("HIDE_SETTINGS_DIALOG_FORM");
    },
    checkThemeOnSave () {
      if (this.active === 'light') {
        this.themeDark = false;
      } else {
        this.themeDark = true;
      }
      LocalStorageService.setThemeApp(this.themeDark);
      this.$vuetify.theme.dark = this.themeDark;
    },
    changeTheme (theme) {
      if (theme === 'light') {
        this.active = "light";
        this.$vuetify.theme.dark = false;
      } else {
        this.active = "dark";
        this.$vuetify.theme.dark = true;
      }
    }
  }
}
</script>

<style lang="scss">

  .contentSettingsDialogForm {
    padding-top: 24px !important;
  }

  .vCardSettingsDialogForm {
    width: 500px;
  }

  .v-input--radio-group__input {
    margin: 0 auto;
    width: 200px !important;

    .v-radio:nth-child(1) {
      margin-right: 40px !important;
    }

    .v-radio:nth-child(2) {
      margin-right: 0px !important;
    }

    div.v-input--selection-controls__input {
      margin-right: 10px !important;
    }
  }

</style>