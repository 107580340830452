<template>
  <main>
    <NavVertical/>
    <v-main>
        <router-view></router-view>
    </v-main>
    <Footer/>
  </main>
</template>

<script>
import NavVertical from '@/components/NavVertical';
import Footer from '@/components/Footer';

export default {
  components: {
    NavVertical,
    Footer
  }
};
</script>

<style lang="scss" scoped>
  main > main.v-main {
    padding-top: 40px !important;
  }
</style>