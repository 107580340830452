import { extend } from "vee-validate";
import { required, validJson } from "vee-validate/dist/rules";

extend("required", {
	...required,
	message: 'validation.required'
});

extend("validEmail", {
	validate(value) {
		const reg = /^[a-z\d]+[\w\d.-]*@(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i;
		return reg.test(value);
	},
	message: "validation.validEmail"
});

extend("validJson", {
	validate(value) {
		try {
			JSON.parse(value);
		} catch (e) {
				return false;
		}
		return true;
	},
	message: "addOrEditTaskDialogForm.errorValidJson"
});