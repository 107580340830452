<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350px">
      <v-overlay :opacity="0.9" :dark="$vuetify.theme.dark">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t(title) }}
            </v-toolbar-title>
          </v-toolbar>
          <v-divider class="titleLine"></v-divider>
          <v-card-text>{{ $t(message) }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click.native="cancel">
              {{ $t("confirmationDailog.btn.cancel") }}
            </v-btn>
            <v-btn id="delete" color="red darken-1" text @click.native="agree">
              {{ $t("confirmationDailog.btn.delete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null
  }),
  methods: {
    open (title, message) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree () {
      this.resolve(true);
      this.dialog = false;
    },
    cancel () {
      this.resolve(false);
      this.dialog = false;
    }
  }
}
</script>

<style>

</style>