<template>
  <v-card :loading="true" class="loader" v-if="$store.state.loadingPage" style="top: 0;"></v-card>
  <v-card :loading="false" class="loader" v-else-if="!$store.state.loadingPage" style="top: -5px;"></v-card>
</template>

<script>
export default {

}
</script>

<style scoped>
  .loader {
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
  }
</style>