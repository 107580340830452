<template>
  <v-app>
    <Header @eventShowSettings="onSettingsClick()" />
    <BaseSnackbar
      :show="$store.state.snackbar.show"
      :type="$store.state.snackbar.type"
      :message="$store.state.snackbar.message"
    />
    <router-view :to="{params: null}"></router-view>
    <SettingsDialogForm/>
    <Footer/>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import BaseSnackbar from "@/components/utils/BaseSnackbar";
import LocalStorageService from "@/services/LocalStorageService";
import SettingsDialogForm from "@/components/utils/SettingsDialogForm";
import Footer from '@/components/Footer';

export default {
  components: {
    Header,
    BaseSnackbar,
    SettingsDialogForm,
    Footer
  },
  mounted() {
    if (!LocalStorageService.getStoreSelectedLanguage()) {
      LocalStorageService.setStoreSelectedLanguage("en");
    }
  },
  methods: {
    onSettingsClick() {
        this.$store.commit("SHOW_SETTINGS_DIALOG_FORM");
      },
  }
};
</script>

<style lang="scss">

  .titleLine {
    margin-bottom: 15px;
  }

  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #313131 !important;
  }

  .theme--light.v-application h1 {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--dark.v-application h1,
  .theme--dark.v-application h1 > .iconHeader {
    color: rgba(255, 255, 255, 0.87) !important;
  }

  h1 {
    font-weight: normal;
    font-size: 24px;

    .iconHeader {
      font-size: 24px !important;
      margin-bottom: 4px !important;
    }

  }

  #app .v-main > .v-main__wrap {

    margin: 0 20px;

    > .container {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  #app.theme--dark .v-main > .v-main__wrap {
    border-bottom: rgb(36, 36, 36) solid 1px;
  }

  #app.theme--light .v-main > .v-main__wrap {
    border-bottom: rgb(182, 182, 182) solid 1px;
  }

  #app.theme--dark .v-main.login > .v-main__wrap,
  #app.theme--light .v-main.login > .v-main__wrap {
    border-bottom: none !important;
  }

</style>