import Vue from 'vue'
import Vuex from 'vuex'
import LocalStorageService from '@/services/LocalStorageService';
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingPage: false,
    loadingDownload: true,
    drawer: true,
    settingsDialogForm: false,
    addOrEditTaskDialogForm: false,
    snackbar: {
      show: false,
      type: "",
      message: ""
    },
    isLogin: LocalStorageService.isLoggedIn(),
    isAdmin: false,
    users: [],
    loggedUserId: ""
  },
  mutations: {
    SHOW_LOADING_PAGE (state) {
      state.loadingPage = true;
    },
    HIDE_LOADING_PAGE (state) {
      state.loadingPage = false;
    },
    TOOGLE_NAV_VERTICAL (state) {
      if (state.drawer) {
        state.drawer = false;
      } else {
        state.drawer = true;
      }
    },
    SET_SNACKBAR (state, status) {
      state.snackbar.show = true;
      state.snackbar.type = status.type;
      state.snackbar.message = status.message;
    },
    UNSET_SNACKBAR (state) {
      state.snackbar.show = false;
      state.snackbar.type = "";
      state.snackbar.message = "";
    },
    SET_LOGGED_USER (state) {
      state.isLogin = true;
    },
    UNSET_LOGGED_USER (state) {
      state.isLogin = false;
    },
    SHOW_SETTINGS_DIALOG_FORM (state) {
      state.settingsDialogForm = true;
    },
    HIDE_SETTINGS_DIALOG_FORM (state) {
      state.settingsDialogForm = false;
    },
    IS_ADMIN (state) {
      state.isAdmin = true;
    },
    SET_DARK_THEME (state) {
      state.dark = true;
    },
    SET_LIGHT_THEME (state) {
      state.dark = false;
    },
    SHOW_ADD_OR_EDIT_TASK_DIALOG_FORM (state) {
      state.addOrEditTaskDialogForm = true;
    },
    HIDE_ADD_OR_EDIT_TASK_DIALOG_FORM (state) {
      state.addOrEditTaskDialogForm = false;
    },
    SET_USERS (state, payload) {
      state.users = payload;
    },
    SET_LOGGED_USER_ID (state, payload) {
      state.loggedUserId = payload;
    }
  },
  actions: {
    setSnackbar({ commit, state }, options) {
      if (state.snackbar.show) {
        commit("UNSET_SNACKBAR");
        setTimeout (() => {
          commit("SET_SNACKBAR", { type: options.type, message: options.message })
        }, 250);
      } else {
        commit("SET_SNACKBAR", { type: options.type, message: options.message })
      }
    },

    async getUsersAsync({ commit, state}, options){
      try {
        const { data }  = await axios.get("/auth/user/data")
        commit("SET_USERS", data)
      } catch (error) {
        console.log(error);
        commit("SET_USERS", [])
      }

    }
  },
  modules: {
  }
})
